<template>
  <div id="accountSafety">
    <header-nav :content="`${$t('pages_router_023')} / ${ $t('pages_router_033')}`" />
    <div class="common-card-body">
      <div v-if="getUsedCheck(pageLabs, 170301)" class="safety-card flex-align">
        <div class="area-left flex-align">
          <img
            src="~@/assets/icons/ic-locked.png"
            class="left-img"
            style="height: 32px"
          >
          <span class="title">{{ $t('pages_user_048') }}</span>
          <span class="sub-title">{{ $t('pages_user_051') }}</span>
        </div>
        <div class="area-right flex-align">
          <a-button
            type="link"
            @click="editPassword('login_update')"
          >{{ $t('pages_user_052') }}</a-button>
        </div>
      </div>
      <div v-if="getUsedCheck(pageLabs, 170302)" class="safety-card flex-align">
        <div class="area-left flex-align">
          <img src="~@/assets/icons/ic-money.png" class="left-img">
          <span class="title">{{ $t('pages_user_049') }}</span>
          <span v-if="isExist" class="sub-title">{{ $t('pages_user_051') }}</span>
        </div>
        <div class="area-right flex-align">
          <a-button
            v-if="isExist"
            type="link"
            @click="editPassword('pay_update')"
          >{{ $t('pages_user_053') }}</a-button>
          <a-button
            v-else
            type="link"
            @click="editPassword('pay_set')"
          >{{ $t('pages_user_065') }}</a-button>
          <a-button
            v-if="isExist"
            type="link"
            @click="editPassword('pay_reset')"
          >{{ $t('pages_user_054') }}</a-button>
        </div>
      </div>
      <div
        v-if="getUsedCheck(pageLabs, 170302)"
        class="safety-card flex-align"
      >
        <div class="area-left flex-align">
          <img
            src="~@/assets/icons/ic-shield.png"
            class="left-img"
          >
          <span class="title">{{ $t('pages_user_050') }}</span>
          <span v-if="bindInfo.isOnOff" class="sub-title">{{ $t('pages_user_056') }}</span>
          <span v-else class="sub-title">{{ $t('pages_user_055') }}</span>
        </div>
        <div
          class="area-right"
          style="text-align: right"
        >
          <a-switch
            v-if="bindInfo.status === '00'"
            v-model:checked="bindInfo.isOnOff"
            style="margin: 0 15px 30px 0"
            @change="switchChange"
          />
          <div class="set-btn">
            <span
              v-if="bindInfo.status === '00' && bindInfo.isOnOff"
              style="margin-right: 24px"
            >
              <MobileOutlined class="main-text-color" />
              {{ $t('pages_user_117') }}: {{ bindInfo.formatMobile }}
            </span>
            <a-button
              v-if="(bindInfo.mobile && bindInfo.isOnOff && bindInfo.status === '00') || !bindInfo.mobile"
              type="primary"
              ghost
              @click="valid = true"
            >{{ bindInfo.mobile ? $t('pages_user_014') : $t('pages_user_117') }}</a-button>
          </div>
        </div>
      </div>
    </div>

    <password-set
      v-model:visible="visible"
      :edit-text="editText"
      @refresh="loadPayPwdExist"
    />

    <mobile-set
      v-model:visible="valid"
      :bind-info="bindInfo"
      @refresh="loadValidMobile"
    />
  </div>
</template>
<script>
import PasswordSet from './components/password-set'
import MobileSet from './components/mobile-set'
import HeaderNav from '@/components/header-nav'
import { getUsedCheck } from '@/util'
import { reactive, toRefs } from 'vue'
import { _account, _pay, _user } from '@/api'
export default {
  name: 'AccountSafety',
  components: {
    'header-nav': HeaderNav,
    'password-set': PasswordSet,
    'mobile-set': MobileSet
  },
  setup () {
    const state = reactive({
      pageLabs: [],
      visible: false,
      valid: false,
      isExist: false,
      editText: 'login_update',
      bindInfo: {
        id: '',
        mobile: '',
        status: '',
        isOnOff: false
      }
    })

    const switchChange = (e) => {
      _user.updateSafetyStatus({
        id: state.bindInfo.id,
        isOnOff: e
      })
    }

    const editPassword = (editText) => {
      state.editText = editText
      state.visible = true
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1703 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const loadPayPwdExist = async () => {
      const res = await _pay.isPayPwdExist()
      if (res.data.succ) state.isExist = res.data.data
    }

    const loadValidMobile = async () => {
      const res = await _user.querySafetySwitch()
      if (res.data.succ) state.bindInfo = res.data.data
      if (res.data.data.mobile) {
        const num = state.bindInfo.mobile.length - 7
        const reg = new RegExp(`(\\d{3})\\d{${num}}(\\d{4})`)
        state.bindInfo.formatMobile = state.bindInfo.mobile.replace(reg, '$1****$2')
      }
    }

    loadChildrenMenu()
    loadPayPwdExist()
    loadValidMobile()

    return {
      getUsedCheck,
      switchChange,
      editPassword,
      loadPayPwdExist,
      loadValidMobile,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#accountSafety {
  .common-card-body {
    padding: 36px 48px;
    .safety-card {
      height: 135px;
      border-radius: 8px;
      border: 1px solid #E9E9E9;
      padding: 0 24px;
      margin-bottom: 24px;
      .area-left {
        .left-img {
          width: 35px;
          height: 35px;
          margin-top: -3px;
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          margin-left: 20px;
        }
        .sub-title {
          margin-left: 12px;
        }
      }
      .area-right {
        margin-left: auto;
        .set-btn {
          display: block;
          margin-right: 15px;
        }
      }
    }
  }
}
.password-form {
  .email-text {
    border: 1px solid #d9d9d9;
    line-height: 30px;
    height: 32px;
    padding: 0 12px;
    width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .form-edit:deep(.ant-form-item-label) {
    white-space: normal;
    overflow: inherit;
  }
}
</style>

