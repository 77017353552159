<template>
  <a-modal
    wrap-class-name="password-form"
    :visible="visible"
    :width="modalWidth"
    :mask-closable="false"
    :title="editText === 'login_update' ? $t('pages_user_062'):
      editText === 'pay_set' ? $t('pages_user_065') :
      editText === 'pay_update' ? $t('pages_user_063') : $t('pages_user_064')"
    :confirm-loading="loading"
    :ok-text="$t('common_text_002')"
    @ok="submit"
    @cancel="cancel"
  >
    <a-form
      ref="editRef"
      class="form-edit"
      label-align="left"
      :model="editForm"
      :rules="editRules"
      :label-col="{span: 5}"
    >
      <a-form-item
        v-if="['login_update', 'pay_update'].includes(editText)"
        name="oldPassword"
        :label="$t('pages_user_057')"
      >
        <a-input-password
          v-model:value="editForm.oldPassword"
          :placeholder="$t('common_text_006') + $t('pages_user_057')"
        />
      </a-form-item>
      <a-form-item
        v-if="editText === 'pay_reset'"
        :required="true"
        :label="$t('pages_user_070')"
      >
        <div class="flex-align">
          <div class="email-text">{{ email }}</div>
          <a-button
            type="primary"
            style="margin-left: auto"
            :disabled="countNum > 0"
            :loading="codeLoading"
            @click="sendCode"
          >{{ countNum > 0 ? `${countNum}${$t('pages_user_018')}` : $t('pages_user_071') }}</a-button>
        </div>
      </a-form-item>
      <a-form-item
        v-if="editText === 'pay_reset'"
        name="code"
        :label="$t('pages_user_020')"
      >
        <a-input
          v-model:value="editForm.code"
          allow-clear
          autocomplete="new-password"
          :placeholder="$t('common_text_006') + $t('pages_user_020')"
        />
      </a-form-item>
      <a-form-item name="newPassword" :label="$t('pages_user_058')">
        <a-input-password
          v-model:value="editForm.newPassword"
          autocomplete="new-password"
          :placeholder="$t('common_text_006') + $t('pages_user_058')"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 5 }">
        <div style="font-weight: 500">{{ $t('pages_user_066') }}</div>
        <div style="margin-top: 8px">{{ $t('pages_user_067') }}</div>
        <div style="margin-top: 8px">{{ $t('pages_user_068') }}</div>
        <div style="margin-top: 8px">{{ $t('pages_user_069') }}</div>
      </a-form-item>
      <a-form-item name="secPassword" :label="$t('pages_user_059')">
        <a-input-password
          v-model:value="editForm.secPassword"
          autocomplete="new-password"
          :placeholder="$t('common_text_006') + $t('pages_user_059')"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, reactive, toRefs, onUnmounted } from 'vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { _user } from '@/api'
import i18n from '@/locale'
import md5 from 'js-md5'

export default {
  name: 'PasswordSet',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    editText: {
      type: String,
      default: () => { return 'login_update' }
    }
  },
  setup (props, ctx) {
    const validPasswordFormat = (rule, value) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/
      if (!value) return Promise.reject(i18n.global.t(rule.field === 'secPassword' ? 'pages_user_059' : 'pages_user_058') + i18n.global.t('common_text_042'))
      if (!pattern.test(value)) return Promise.reject(i18n.global.t('pages_user_060'))
      if (rule.field === 'secPassword' && value !== state.editForm.newPassword) return Promise.reject(i18n.global.t('pages_user_061'))
      return Promise.resolve()
    }

    let timer = ref(null)
    const editRef = ref(null)
    const store = useStore()

    const state = reactive({
      email: store.getters.getUserInfo.email,
      modalWidth: localStorage.getItem('locale-language') === 'zh' ? 488 : 728,
      countNum: 0,
      codeLoading: false,
      loading: false,
      editForm: {
        oldPassword: '',
        newPassword: '',
        secPassword: '',
        verificationCode: '',
        code: ''
      },
      editRules: {
        oldPassword: [{ required: true, message: i18n.global.t('pages_user_057') + i18n.global.t('common_text_042') }],
        newPassword: [{ required: true, validator: validPasswordFormat }],
        secPassword: [{ required: true, validator: validPasswordFormat }],
        code: [{ required: true, message: i18n.global.t('pages_user_020') + i18n.global.t('common_text_042') }]
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const sendCode = async () => {
      state.codeLoading = true
      const res = await _user.sendCodeEmail()
      state.codeLoading = false
      if (res.data.succ) {
        state.countNum = 60
        timer = setInterval(() => {
          state.countNum--
          if (state.countNum === 0) clearInterval(timer)
        }, 1000)
      } else {
        message.error(res.data.resMsg)
      }
    }

    const cancel = () => {
      editRef.value.resetFields()
      ctx.emit('update:visible', false)
    }

    const submit = () => {
      editRef.value.validateFields()
        .then(values => {
          state.loading = true
          const newPayPwd = [{ pwd: md5(state.email + values.newPassword), pwdIndex: 0 }]
          const oldPayPwd = [{ pwd: md5(state.email + values.oldPassword), pwdIndex: 0 }]
          if (props.editText === 'login_update') {
            return _user.modifyPassword(values)
          } else if (props.editText === 'pay_set') {
            return _user.setPayPassword(newPayPwd)
          } else if (props.editText === 'pay_update') {
            return _user.modifyPayPassword({ newPayPwd, oldPayPwd })
          } else {
            return _user.resetPayPassword({ newPayPwd, code: values.code })
          }
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('pages_user_072'))
            ctx.emit('refresh')
            cancel()
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    return {
      editRef,
      sendCode,
      cancel,
      submit,
      ...toRefs(state)
    }
  }
}
</script>

<style>

</style>
