<template>
  <a-modal
    wrap-class-name="valid-form"
    :visible="visible"
    :mask-closable="false"
    :title="bindInfo.mobile ? $t('pages_user_014') : $t('pages_user_117')"
    :confirm-loading="loading"
    :ok-text="$t('common_text_002')"
    @ok="submit"
    @cancel="cancel"
  >
    <a-form
      ref="validRef"
      class="form-edit"
      layout="vertical"
      :model="validForm"
    >
      <a-form-item
        name="mobile"
        :label="bindInfo.mobile ? $t('pages_user_118') : $t('pages_user_005')"
        :rules="{required: true}"
      >
        <div class="flex-align">
          <a-form-item-rest>
            <a-select
              v-model:value="validForm.areaCode"
              show-search
              style="flex: 1"
            >
              <template #suffixIcon>
                <img
                  src="~@/assets/icons/ic-choose.png"
                  style="
                      width: 11px;
                      margin-top: -3px;
                    "
                >
              </template>
              <a-select-option
                v-for="(item, index) in areaCodes"
                :key="index"
                :value="item"
                :label="item"
              />
            </a-select>
          </a-form-item-rest>
          <a-input
            v-model:value="validForm.mobile"
            allow-clear
            style="
                margin-left: 24px;
                flex: 3;
              "
            :maxlength="11"
            :placeholder="$t('common_text_006') + $t('pages_user_005')"
          />
        </div>
      </a-form-item>
      <a-form-item
        name="verificationCode"
        :label="$t('pages_user_020')"
        :rules="{required: true}"
      >
        <div class="flex-align">
          <a-input
            v-model:value="validForm.verificationCode"
            allow-clear
            :placeholder="$t('common_text_006') + $t('pages_user_020')"
          />
          <a-button
            type="primary"
            style="margin-left: 24px"
            :disabled="countNum > 0"
            :loading="codeLoading"
            @click="sendCode('codeLoading', 'countNum', 'timerNew')"
          >{{ countNum > 0 ? `${countNum}${$t('pages_user_018')}` : $t('pages_user_017') }}</a-button>
        </div>
      </a-form-item>
      <a-form-item
        v-if="bindInfo.mobile"
        name="originalVerificationCode"
        :label="$t('pages_user_119') + $t('pages_user_120', [bindInfo.formatMobile])"
        :rules="{required: true}"
      >
        <div class="flex-align">
          <a-input
            v-model:value="validForm.originalVerificationCode"
            allow-clear
            :placeholder="$t('common_text_006') + $t('pages_user_020')"
          />
          <a-button
            type="primary"
            style="margin-left: 24px"
            :disabled="countOriNum > 0"
            :loading="codeOriLoading"
            @click="sendCode('codeOriLoading', 'countOriNum', 'timerOld')"
          >{{ countOriNum > 0 ? `${countOriNum}${$t('pages_user_018')}` : $t('pages_user_017') }}</a-button>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, reactive, toRefs, onMounted, onUnmounted } from 'vue'
import { message } from 'ant-design-vue'
import { _user } from '@/api'
import i18n from '@/locale'
export default {
  name: 'MobileSet',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    bindInfo: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, ctx) {
    const validRef = ref(null)
    const state = reactive({
      loading: false,
      timerOld: null,
      timerNew: null,
      countNum: 0,
      codeLoading: false,
      countOriNum: 0,
      codeOriLoading: false,
      areaCodes: [],
      validForm: {
        mobile: '',
        verificationCode: '',
        originalVerificationCode: '',
        areaCode: ''
      }
    })

    onMounted(() => {
      loadPhoneAreaCode()
    })

    onUnmounted(() => {
      clearInterval(state.timerOld)
      clearInterval(state.timerNew)
    })

    const sendCode = async (loading, num, timer) => {
      const { mobile, areaCode } = state.validForm
      state[loading] = true
      const res = await _user.sendVerificationCode({
        phone: loading === 'codeLoading' ? mobile : props.bindInfo.mobile,
        areaCode: loading === 'codeLoading' ? areaCode : props.bindInfo.areaCode
      })
      state[loading] = false
      if (res.data.succ) {
        state[num] = 60
        state[timer] = setInterval(() => {
          state[num]--
          if (state[num] === 0) clearInterval(state[timer])
        }, 1000)
      } else {
        message.error(res.data.resMsg)
      }
    }

    const cancel = () => {
      validRef.value.resetFields()
      ctx.emit('update:visible', false)
    }

    const submit = () => {
      validRef.value.validateFields()
        .then(() => {
          state.loading = true
          if (props.bindInfo.status === '00') {
            const data = Object.assign({}, state.validForm, {
              id: props.bindInfo.id,
              originalAreaCode: props.bindInfo.areaCode,
              originalMobile: props.bindInfo.mobile
            })
            return _user.updateBindPhone(data)
          }
          return _user.bindMobile(state.validForm)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('pages_user_072'))
            ctx.emit('refresh')
            cancel()
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const loadPhoneAreaCode = async () => {
      const res = await _user.getPhoneAreaCode()
      if (res.data.succ) {
        state.areaCodes = res.data.data
        for (const item of state.areaCodes) {
          if (item === '86') state.validForm.areaCode = '86'
        }
        if (!state.validForm.areaCode) state.validForm.areaCode = state.areaCodes[0]
      }
    }

    return {
      validRef,
      sendCode,
      cancel,
      submit,
      ...toRefs(state)
    }
  }
}
</script>

<style>

</style>
